<template>
  <div>
    <b-card 
      class="file-preview overflow-hidden cursor-pointer" 
      body-class="p-0"
      @click="openZoomModal(src, type)"
    >
      <button-close
        v-if="cancelable"
        class="file-preview__cancel zindex-4"
        :disabled="disabled"
        @click="$emit('cancel', index)"
      />
      <safe-img
        v-if="type === 'image'"
        ref="safeImg"
        :src="getSrc()"
        :placeholder="placeholderImage"
        :class="hasMaxHeight ? 'max-height' : ''"
        class="d-block mw-100 file-preview__content"
      />
      <img
        v-else-if="type === 'tiff'"
        ref="normalImg"
        :src="getSrc()"
        :alt="fileName"
        @error="$event.target.src = placeholderImage"
        :class="hasMaxHeight ? 'max-height' : ''"
        class="d-block mw-100 file-preview__content"
      />
      <video-player
        v-else-if="type === 'video'"
        :src="src"
        :type="mimeType"
        class="mw-100 file-preview__content"
      />
      <b-link
        v-else
        :href="src"
        target="_blank"
        class="file-preview__content d-flex flex-column align-items-center"
      >
        <feather-icon v-if="type==='audio'" icon="MusicIcon" size="128" />
        <feather-icon v-else icon="FileTextIcon" size="128" />
        <h5 v-if="fileName" class="mt-1 px-25">
          {{ fileName }}
        </h5>
      </b-link>
    </b-card>

    <!-- Zoom Modal -->
    <b-modal
      v-model="showModal"
      size="xl"
      hide-footer
      centered
      body-class="p-0"
    >
      <template #modal-header="{ close }">
        <h5 class="modal-title">
          {{ fileName || 'Zoom Preview' }}
        </h5>
        <button-close @click="close()" />
      </template>
      <safe-img
        v-if="type === 'image'"
        :src="getSrc()"
        :placeholder="placeholderImage"
        class="d-block image-preview"
      />
      <img
        v-else-if="type === 'tiff'"
        :src="getSrc()"
        :alt="fileName"
        @error="$event.target.src = placeholderImage"
        class="d-block image-preview"
      />
      <video-player
        v-else-if="type === 'video'"
        :src="src"
        :type="mimeType"
        class="mw-100 file-preview__content"
      />
    </b-modal>
  </div>
</template>

<script>
import { BCard, BLink, BModal } from 'bootstrap-vue';
import ButtonClose from '@core/components/buttons/ButtonClose.vue';
import VideoPlayer from '@core/components/video-player/VideoPlayer.vue';
import SafeImg from '@core/components/safe-img/SafeImg.vue';
import Tiff from 'tiff.js'

export default {
  name: 'FilePreview',
  components: {
    BCard, BLink, ButtonClose, VideoPlayer, SafeImg, BModal,
  },
  props: {
    src: {
      type: String,
      required: true,
    },
    mimeType: {
      type: String,
      required: true,
    },
    fileName: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      required: false,
    },
    cancelable: {
      type: Boolean,
      default: false,
    },
    disabled: Boolean,
    placeholderImage: {
      type: String,
      default: '',
    },
    hasMaxHeight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
    }
  },
  computed: {
    type() {
      if (this.mimeType === 'image' || this.mimeType.startsWith('image/')) {
        if (this.mimeType === 'image/jpeg' || 
            this.mimeType.endsWith('jfif') ||
            this.src.toLowerCase().endsWith('.jfif')) {
          return 'image';
        }
        if (this.mimeType === 'image/tiff' || 
            this.mimeType === 'image/x-tiff' ||
            this.mimeType.endsWith('tiff') ||
            this.mimeType.endsWith('tif')) {
          return 'tiff';
        }
        return 'image';
      }
      if (this.mimeType === 'video' || this.mimeType.startsWith('video/')) {
        return 'video';
      }
      if (this.mimeType === 'audio' || this.mimeType.startsWith('audio/')) {
        return 'audio';
      }
      if(this.mimeType === 'image/tiff' || this.mimeType === 'image/x-tiff' || this.mimeType === 'jfif' || this.mimeType.endsWith('tiff') || this.mimeType.endsWith('tif')) {
        return 'tiff';
      }
      return 'document';
    },
  },
  methods: {
    getSrc() {
      const imageSrc = this.src.split('?')[0];
      if (imageSrc.includes('.tiff') || imageSrc.includes('.tif')) {
        this.getTiffPreview()
          .then(dataUrl => {
            if (this.$refs.normalImg) {
              this.$refs.normalImg.src = dataUrl;
            }
          })
          .catch(error => {
            console.error('Error getting TIFF preview:', error);
            if (this.$refs.normalImg) {
              this.$refs.normalImg.src = this.placeholderImage;
            }
          });
        return this.placeholderImage || '';
      }
      return this.src || '';
    },
    openZoomModal(src, type) {
      this.showModal = true;
    },
    async loadTiff(src) {
      console.log('loading tiff image... src:', src);
      try {
        const response = await fetch(src);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const arrayBuffer = await response.arrayBuffer();
        // console.log('ArrayBuffer size:', arrayBuffer.byteLength);
        
        // Skip TIFF.js and go straight to browser decoder
        const blob = new Blob([arrayBuffer], { type: 'image/tiff' });
        const objectUrl = URL.createObjectURL(blob);
        
        return new Promise((resolve, reject) => {
          const img = new Image();
          
          img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            URL.revokeObjectURL(objectUrl);
            resolve(canvas.toDataURL('image/png'));
          };
          
          img.onerror = () => {
            URL.revokeObjectURL(objectUrl);
            console.error('Failed to decode TIFF with browser');
            // If browser can't handle TIFF, try converting to PNG using data URI
            try {
              const base64 = btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)));
              resolve(`data:image/tiff;base64,${base64}`);
            } catch (e) {
              console.error('Failed to create data URI:', e);
              reject(e);
            }
          };
          
          img.src = objectUrl;
        });
        
      } catch (error) {
        console.error('Error loading TIFF:', error);
        return this.placeholderImage;
      }
    },
    async getTiffPreview() {
      if (this.type === 'tiff') {
        return await this.loadTiff(this.src);
      }
      return this.src;
    }
  },
};
</script>

<style lang="scss" scoped>
  .file-preview {
    position: relative;
    display: inline-block;
    &__cancel {
      position: absolute;
      top: 5px;
      right: 5px;
    }
    &__content {
      min-width: 100px;
      min-height: 50px;
    }
  }
  .max-height {
    max-height: 180px;
    height: 180px;
    width: auto;
  }
  .cursor-pointer {
    cursor: pointer;
  }

  // Update modal content styles
  :deep(.modal-content) {
    max-height: calc(100vh - 6rem);
    
    .modal-body {
      display: flex;
      justify-content: center;
      align-items: center;
      
      // img {
      //   max-height: calc(100vh - 12rem);
      //   height: 100%;
      //   width: auto;
      //   margin: auto;
      // }
    }
  }

  .image-preview {
    max-height: calc(100vh - 12rem);
    height: 100%;
    width: auto !important;
    margin: 1rem auto !important;
  }
</style>
